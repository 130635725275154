/* La clase ProfileService es un servicio Angular que extiende el NgEntityService y se usa para
interactuar con ProfileStore. */
import { Injectable } from '@angular/core';
import { ProfileStore, ProfileState } from './profile.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class ProfileService extends NgEntityService<ProfileState> {

  constructor(protected store: ProfileStore) {
    super(store);
  }

}
