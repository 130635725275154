
/* La clase CategoryService es un servicio Angular que extiende el NgEntityService y se usa para
interactuar con CategoryStore. */
import { Injectable } from '@angular/core';
import { CategoryStore, CategoryState } from './category.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class CategoryService extends NgEntityService<CategoryState> {

  constructor(protected store: CategoryStore) {
    super(store);
  }

}
