/* La clase TreeService es un servicio Angular que extiende el NgEntityService y se usa para interactuar
con TreeStore. */
import { Injectable } from '@angular/core';
import { TreeStore, TreeState } from './tree.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class TreeService extends NgEntityService<TreeState> {

  constructor(protected store: TreeStore) {
    super(store);
  }

}
