
/* La clase EnterpriseContractService es un servicio Angular que proporciona métodos para recuperar
datos estadísticos de una API. */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnterpriseContractService {
  constructor(private httpClient: HttpClient) {}

/**
 * La función getStatistics envía una solicitud GET al punto final /statistics con paginación opcional
 * parámetros.
 * @ param {any} data - El parámetro `data` es un objeto que contiene las siguientes propiedades:
 * @ devuelve una solicitud HTTP GET al extremo `/statistics` con los parámetros especificados.
 */
  getStatistics(data: any) {
    const params = new HttpParams()
      .set('page', String(data.page || ''))
      .set('perPage', String(data.perPage || ''));
    return this.httpClient.get<any>(`${environment.api}/statistics`, {
      params,
    });
  }

/**
 * La función `findByPatternStatistics` envía una solicitud GET al servidor para recuperar estadísticas de
 * patrones que coinciden con una palabra clave determinada.
 * @ param {cualquier} palabra clave: el parámetro de palabra clave se usa para especificar la palabra clave de búsqueda para encontrar
 * estadísticas de patrones. Puede ser de cualquier tipo, pero se convertirá en una cadena antes de usarse en
 * la búsqueda. Si no se proporciona ninguna palabra clave, se utilizará una cadena vacía como valor predeterminado.
 * @ devuelve una solicitud HTTP GET al extremo de la API especificado, que es
 * `${environment.api}/statistics/search/patterns`. La solicitud incluye un parámetro llamado 'palabra clave'
 * con un valor de `String(palabra clave || '')`. La respuesta de la llamada a la API es del tipo `cualquiera`.
 */
  findByPatternStatistics(keyword: any) {
    const params = new HttpParams().set('keyword', String(keyword || ''));
    return this.httpClient.get<any>(
      `${environment.api}/statistics/search/patterns`,
      { params },
    );
  }

  findById(id: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.api}/statistics/${id}`, {});
  }
}
