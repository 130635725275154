/* La clase ContractingEntityQuery es un servicio Angular que proporciona capacidades de consulta para el
Almacén de entidades contratantes. */
import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ContractingEntityStore, ContractingEntityState} from './contracting-entity.store';

@Injectable({providedIn: 'root'})
// @ts-ignore
export class ContractingEntityQuery extends QueryEntity<ContractingEntityState> {

  constructor(protected store: ContractingEntityStore) {
    super(store);
  }

}
