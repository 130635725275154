/* Este código define una clase `ProfileStore` que extiende la clase `EntityStore` desde el
Biblioteca `@datorama/akita`. */
import { Injectable } from '@angular/core';
import { Profile } from './profile.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ProfileState extends EntityState<Profile> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'profiles', idKey: '_id' })
export class ProfileStore extends EntityStore<ProfileState> {
  constructor() {
    super();
  }
}
