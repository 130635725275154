
/* Este código está definiendo una tienda para administrar patrones en una aplicación Angular usando el estado de Akita
biblioteca de gestión. */
import { Injectable } from '@angular/core';
import { Pattern } from './pattern.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface PatternState extends EntityState<Pattern> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patterns', idKey: '_id' })
export class PatternStore extends EntityStore<PatternState> {
  constructor() {
    super();
  }
}
