/* El código está definiendo una clase `TreeStore` que extiende la clase `EntityStore` desde el
Biblioteca `@datorama/akita`. */
import { Injectable } from '@angular/core';
import { Tree } from './tree.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TreeState extends EntityState<Tree> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'trees', idKey: '_id' })
export class TreeStore extends EntityStore<TreeState> {
  constructor() {
    super();
  }
}
