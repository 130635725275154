
/* La clase `PatternService` es un servicio Angular que extiende `NgEntityService` y se usa para
interactuar con `PatternStore` para administrar el estado de los patrones. */
import { Injectable } from '@angular/core';
import { PatternStore, PatternState } from './pattern.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class PatternService extends NgEntityService<PatternState> {

  constructor(protected store: PatternStore) {
    super(store);
  }

}
