/* La clase `PatternQuery` es un servicio Angular que extiende la clase `QueryEntity` de Akita
biblioteca y proporciona métodos para consultar el estado de `PatternStore`. */
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatternStore, PatternState } from './pattern.store';

@Injectable({ providedIn: 'root' })
export class PatternQuery extends QueryEntity<PatternState> {

  constructor(protected store: PatternStore) {
    super(store);
  }

}
