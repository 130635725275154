/* La clase TreeQuery es un servicio de Angular que proporciona métodos para consultar el estado del
TreeStore. */
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TreeStore, TreeState } from './tree.store';

@Injectable({ providedIn: 'root' })
export class TreeQuery extends QueryEntity<TreeState> {

  constructor(protected store: TreeStore) {
    super(store);
  }

}
